"use strict";

var formValidation = require("base/components/formValidation");
var createErrorNotification = require("base/components/errorNotification");
var scrollAnimate = require("base/components/scrollAnimate");
var recaptchaCheck = require("../components/recaptchaCheck");
var showAlert = require("../components/showAlert");
var customerHelpers = require("../checkout/customer");
var inputmask = require("inputmask").default;
var drawer = require("../components/drawer");
var urlUtils = require("storefront/components/urlUtils");
var debounce = require("lodash/debounce");
var redirectUrl = "";

function attachNavOnResetPasswordDrawerEvent(email) {
    $("a.open-reset-password").click(function (e) {
        e.preventDefault();
        $("form#reset-password-form input#reset-password-email").val(email);
        drawer.openDrawer(".js-reset-password-drawer");
    });
}

function clearResetForm() {
    $(".reset-response").remove();
    $(".reset-password-title").show();
    $(".request-password-body").children(".form-group").show();
    $("#reset-password-email").val("");
    $("#reset-password-email").removeClass("is-invalid");
    $("#submit-reset-email")
        .text("Send")
        .removeClass("js-drawer-open")
        .removeAttr("data-target", ".js-login-drawer")
        .attr("type", "submit");
}

function clearLoginForm() {
    let loginForms = $(".registered-form");

    loginForms.each(function (formIndex, form) {
        let inputs = $(form).find("input");

        inputs.each(function (inputIndex, input) {
            if ($(input).attr("type") === "checkbox" && $(input).attr("id") !== "add-to-email-list") {
                $(input).prop("checked", false);
            } else if ($(input).attr("type") !== "hidden") {
                $(input).val("");
                $(input).removeClass("is-invalid");
            }
        });
        $(".already-registered").addClass("d-none");
        $(".js-join-partially-enrolled").addClass("d-none");
        $(".helper-text-mobile").removeClass("d-none");
        $("#registration-form-phone").removeAttr("disabled");
        $(form).find(".alert").remove();
    });
}

/**
 * Appends the product ID of the product in the wishlist to the login and registration URLs.
 * @param {object} self - The clicked element.
 */
function appendWishlistProductIdToLoginUrls(self) {
    const $self = $(self);
    if ($self.attr("data-pid")) {
        const wishlistProductId = $self.attr("data-pid");
        $(".js-input-pid").val(wishlistProductId);
    }
}

function getWishlistProductId() {
    const $pid = $(".js-input-pid");
    if ($pid && $pid.length > 0) {
        return $pid.val();
    }
}


function toggleRequiredMobilePhone() {
    if (!$(".js-register-mobile-phone").hasClass("required")) {
        $(".js-register-mobile-phone").addClass("required");
    } else {
        $(".js-register-mobile-phone").removeClass("required");
    }
}

var debounceRequiredChange = debounce(toggleRequiredMobilePhone, 100);

/**
 * Toggles pre-enroll fields based on a condition
 * @param {boolean} condition - the condition to check
 * @param {Object} data - the data object containing resettingCustomerEmail and primaryWmeId
 * @returns {void}
 */
function togglePreEnrollFields(condition, data) {
    if (condition === true) {
        $(".js-form-email").val(data.resettingCustomerEmail);
        $(".js-preroll-email").val(data.resettingCustomerEmail);
        $(".js-preroll-email").prop("disabled", true);
        $("#registration-form-phone").val(data.primaryWmeId);
        $("#registration-form-phone").prop("disabled", true);
        $(".js-join-partially-enrolled").removeClass("d-none");
        $(".helper-text-mobile").addClass("d-none");
    } else {
        $(".js-form-email").val(data.resettingCustomerEmail);
        $(".js-preroll-email").val("");
        $(".js-preroll-email").prop("disabled", false);
        $("#registration-form-phone").val("");
        $("#registration-form-phone").prop("disabled", false);
        $(".js-join-partially-enrolled").addClass("d-none");
        $(".helper-text-mobile").removeClass("d-none");
    }
}

module.exports = {
    initialize: function () {
        $(".js-icon-eye").on("click", function () {
            if ($(this).siblings("input").attr("type") == "password") {
                $(this).siblings("input").attr("type", "text");
            } else {
                $(this).siblings("input").attr("type", "password");
            }
        });

        return;
    },
    login: function () {
        $("form.login").submit(function (e) {
            var form = $(this);
            var wishlistProductId = getWishlistProductId();
            var companyTag = window.sweepstakeOnLogin ? window.sweepstakeOnLogin.companyTag : null;
            var sweepstakeEntry = window.cheetahSweepstakeOnLogin ? window.cheetahSweepstakeOnLogin.sweepstakeEntry : null;
            var pageId = $(".js-page-id").length > 0 ? $(".js-page-id").val() : null;

            e.preventDefault();
            form.spinner().start();

            if ($(".registered-form.login .alert").length) {
                $(".registered-form.login .alert").addClass("d-none");
            }

            let captchaAction = "Login";
            recaptchaCheck.getRecaptchaToken(function (token) {
                let formData = form.serializeArray();
                formData =  [
                    ...formData,
                    { "name": "wishlistProductId", "value" : wishlistProductId },
                    { "name": "gRecaptchaResponse", "value": token},
                    { "name": "captchaAction", "value": captchaAction}
                ];

                if (companyTag !== null) {
                    formData.push({ "name": "companyTag", "value": companyTag });
                    delete window.sweepstakeOnLogin;
                }

                if (sweepstakeEntry !== null) {
                    formData.push({ "name": "sweepstakeEntry", "value": sweepstakeEntry });
                    formData.push({ "name": "sweepsStakeSignup", "value": window.cheetahSweepstakeOnLogin.sweepsStakeSignup });
                    delete window.cheetahSweepstakeOnLogin;
                }

                if (pageId !== null) {
                    formData.push({ "name": "pageId", "value": pageId });
                }

                var url = form.attr("action");
                $("form.login").trigger("login:submit", e);
                $.ajax({
                    url: url,
                    type: "post",
                    dataType: "json",
                    data: formData,
                    success: function (data) {
                        if (!data.success) {
                            form.spinner().stop();
                            formValidation(form, data);
                            attachNavOnResetPasswordDrawerEvent(data.email);
                            $("form.login").trigger("login:error", data);

                            window.dispatchEvent(new CustomEvent("loginFailed", {
                                detail: {
                                    fields: data.aFieldErrors ? data.aFieldErrors : data.error,
                                }
                            }));
                        } else {
                            $("form.login").trigger("login:success", data);

                            if (data.showWishListsModal) {
                                const newurl = urlUtils.updateURLWithParam(window.location.href, "showWishListsModal", data.showWishListsModal);
                                window.location.href = newurl;

                                return;
                            } else {
                                redirectUrl = data.redirectUrl;
                            }

                            window.dispatchEvent(new CustomEvent("loginSuccess", {
                                detail: {
                                    ...data.aLogin,
                                }
                            }));

                            if (redirectUrl.length > 0) {
                                location.href = redirectUrl;
                            } else {
                                if ($(".page").data("refresh")) {
                                    location.reload();
                                } else {
                                    location.href = data.redirectUrl;
                                }
                            }

                            scrollAnimate();
                        }
                    },
                    error: function (data) {
                        if (data.responseJSON.redirectUrl) {
                            window.location.href = data.responseJSON.redirectUrl;
                        } else {
                            $("form.login").trigger("login:error", data);
                            form.spinner().stop();
                        }
                    }
                });
                return false;
            }, captchaAction);
        });
    },

    register: function () {
        $("form.registration").submit(function (e) {
            var form = $(this);
            var wishlistProductId = getWishlistProductId();
            var companyTag = window.sweepstakeOnLogin ? window.sweepstakeOnLogin.companyTag : null;
            var sweepstakeEntry = window.cheetahSweepstakeOnLogin ? window.cheetahSweepstakeOnLogin.sweepstakeEntry : null;
            var pageId = $(".js-page-id").length > 0 ? $(".js-page-id").val() : null;

            e.preventDefault();
            form.spinner().start();
            let captchaAction = "Registration2";
            recaptchaCheck.getRecaptchaToken(function (token) {
                let formData = form.serializeArray();

                var phoneFormData = formData.find(function (element) {
                    return element.name === "dwfrm_profile_customer_phone";
                });

                if (phoneFormData && phoneFormData.value) {
                    phoneFormData.value = phoneFormData.value.replace(/[^0-9]/g, "");
                }

                if (!phoneFormData) {
                    formData.push({ "name": "dwfrm_profile_customer_phone", "value": "0000000000" });
                }

                formData =  [
                    ...formData,
                    { "name": "wishlistProductId", "value" : wishlistProductId },
                    { "name": "gRecaptchaResponse", "value": token},
                    { "name": "captchaAction", "value": captchaAction}
                ];

                if (companyTag !== null) {
                    formData.push({ "name": "companyTag", "value": companyTag });
                    delete window.sweepstakeOnLogin;
                }

                if (sweepstakeEntry !== null) {
                    formData.push({ "name": "notRedirectToMyAccount", "value": true });
                    formData.push({ "name": "sweepstakeEntry", "value": sweepstakeEntry });
                    formData.push({ "name": "sweepsStakeSignup", "value": window.cheetahSweepstakeOnLogin.sweepsStakeSignup });
                    delete window.cheetahSweepstakeOnLogin;
                }

                if (pageId !== null) {
                    formData.push({ "name": "pageId", "value": pageId });
                }

                const active = $(".login-form-nav [aria-selected='true']").attr("aria-controls");
                if (form.attr(`data-${active}-url`)) {
                    const setAction = form.attr(`data-${active}-url`);
                    form.attr("action", setAction);
                }
                var url = form.attr("action");
                $("form.registration").trigger("login:register", e);
                $.ajax({
                    url: url,
                    type: "post",
                    dataType: "json",
                    data: formData,
                    /**
                     * Success callback function for an AJAX request
                     * @param {Object} data - The response data from the AJAX request
                     * @returns {void}
                     */
                    success: function (data) {
                        if (!data.success && !data.isPartialEnrollee) {
                            if (data.memberIdInUse) {
                                const $errorWrapper = $(".js-join-error-alert");
                                const $errorMsg = $errorWrapper.find(".alert");
                                if ($errorMsg.length > 0) {
                                    $errorMsg.remove();
                                }
                                createErrorNotification($(".js-join-error-alert"), data.parsedHtmlError);
                                customerHelpers.methods.initLoginTabEvents();
                                form.spinner().stop();
                                return;
                            }
                            $("form.registration").trigger("login:register:error", data);
                            formValidation(form, data);
                            customerHelpers.methods.initLoginTabEvents();

                            window.dispatchEvent(new CustomEvent("joinFailed", {
                                detail: {
                                    fields: data.aFieldErrors || [],
                                    formName: "join",
                                    type: "form error"
                                }
                            }));

                            form.spinner().stop();

                        } else {

                            window.dispatchEvent(new CustomEvent("joinSuccess", {
                                detail: {
                                    ...data.aRegister,
                                }
                            }));

                            if (data.showWishListsModal) {
                                const newurl = urlUtils.updateURLWithParam(window.location.href, "showWishListsModal", data.showWishListsModal);
                                window.location.href = newurl;

                                return;
                            } else {
                                redirectUrl = data.redirectUrl;
                            }

                            $("form.registration").trigger("login:register:success", data);

                            if (redirectUrl.length > 0) {
                                location.href = redirectUrl;
                            } else {
                                if ($(".page").data("refresh")) {
                                    location.reload();
                                } else {
                                    location.href = data.redirectUrl;
                                }
                            }
                        }
                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            const $errorWrapper = $(".js-join-error-alert");
                            const $errorMsg = $errorWrapper.find(".alert");
                            if ($errorMsg.length > 0) {
                                $errorMsg.remove();
                            }
                            createErrorNotification($errorWrapper, err.responseJSON.errorMessage);
                        }

                        form.spinner().stop();
                    }
                });
                return false;
            }, captchaAction);
        });
    },

    resetPassword: function () {
        $(".reset-password-form").submit(function (e) {
            e.preventDefault();
            let form = $(this);
            let url = form.attr("action");
            $(".reset-password-form").trigger("login:register", e);
            $.ajax({
                url: url,
                type: "post",
                dataType: "json",
                data: form.serialize(),
                /**
                 * Handles the success response from an AJAX request
                 * @param {Object} data - The response data object
                 */
                success: function (data) {
                    if (!data.success) {
                        formValidation(form, data);
                    } else if (data.isPartialEnrollee) {
                        if ($(".checkout-page") && $(".checkout-page").length > 0) {
                            $(".js-reset-drawer-close").trigger("click");
                            $(".js-register-tab").trigger("click");
                            $("#registration-form-email").val(data.resettingCustomerEmail);
                            $(".js-continue-step").trigger("click");
                        } else {
                            let customerForm = $("#guest-customer");
                            let submitAction = customerForm.data("submit-action");
                            $(".js-login-drawer-open[data-option=join]").trigger("click");
                            $(".js-account-join-first").addClass("d-none");
                            $(".js-account-join-second").removeClass("d-none");
                            customerForm.attr("action", submitAction);
                        }
                        togglePreEnrollFields(true, data);
                        window.isPartialEnrolleeFirst = true;
                    } else if (data.emailNotExist) {
                        if ($(".checkout-page") && $(".checkout-page").length > 0) {
                            $(".js-reset-drawer-close").trigger("click");
                            $(".js-register-tab").trigger("click");
                        } else {
                            $(".reset-password-form").addClass("d-none");
                            $(".js-email-not-exist-container").removeClass("d-none");

                            $(".js-email-not-exist-btn").on("click", function () {
                                $(".js-login-drawer-open[data-option=join]").trigger("click");
                            });
                        }

                        togglePreEnrollFields(false, data);
                    } else {
                        $(".reset-password-title").hide();
                        $(".request-password-body").children(".form-group").hide();
                        $(".js-reset-drawer-title").text(data.receivedMsgHeading);
                        $(".request-password-body").append("<span class='reset-response text text-base medium text-color-t200'>" + data.receivedMsgBody + "</span>");

                        if (!data.mobile) {
                            $("#submit-reset-email")
                                .text(data.buttonText)
                                .addClass("js-drawer-open")
                                .attr("data-target", ".js-login-drawer")
                                .attr("type", "button");
                        } else {
                            $(".send-email-btn").empty()
                                .html("<a href='"
                                    + data.returnUrl
                                    + "' class='btn btn-primary btn-block'>"
                                    + data.buttonText + "</a>"
                                );
                        }

                        window.dispatchEvent(new CustomEvent("completePasswordReset", {}));
                    }
                }
            });
            return false;
        });
    },

    sendPasswordlessForm: function () {
        $(".passwordless-form").submit(function (e) {
            e.preventDefault();
            let form = $(this);
            let url = form.attr("action");
            $(".passwordless-form").trigger("login:register", e);
            $.ajax({
                url: url,
                type: "post",
                dataType: "json",
                data: form.serialize(),
                success: function (data) {
                    if (!data.success) {
                        if (data.errorCode ==="account.not.found") {
                            drawer.openDrawer(".js-confirmation-no-account-drawer");
                        } else {
                            formValidation(form, data);
                            attachNavOnResetPasswordDrawerEvent(data.email);
                        }
                    } else {
                        drawer.openDrawer(".js-confirmation-passwordless-drawer");
                    }
                }
            });
            return false;
        });
    },

    joinContinueStep: function () {
        $(".js-account-continue-step").on("click", function (e) {
            e.preventDefault();
            let customerForm = $(this).parents("form");

            // TODO: separate join steps forms and remove this workaround
            $("#guest-customer input").removeClass("is-invalid");

            let captchaAction = "Registration1";
            recaptchaCheck.getRecaptchaToken(function (token) {
                let defer = $.Deferred();
                let newFormData = customerForm.serializeArray();
                let submitAction = customerForm.data("submit-action");

                newFormData = [
                    ...newFormData,
                    { "name": "gRecaptchaResponse", "value": token },
                    { "name": "captchaAction", "value": captchaAction}
                ];

                $.ajax({
                    url: customerForm.attr("action"),
                    type: "post",
                    data: newFormData,
                    success: function (data) {
                        if (data.success === false || data.error === true) {
                            showAlert(data);
                        }

                        if (data.nextStep) {
                            $(".js-account-join-first").addClass("d-none");
                            $(".js-account-join-second").removeClass("d-none");
                            customerForm.attr("action", submitAction);

                            if (data.isPartialEnrollee) {
                                $(".js-join-partially-enrolled").removeClass("d-none");
                                $(".js-form-email").val(data.customerEmail);
                                $(".js-preroll-email").val(data.customerEmail);
                                $(".js-preroll-email").attr("disabled", "disabled");
                                $("#registration-form-phone").val(data.primaryWmeId);
                                $("#registration-form-phone").attr("disabled", "disabled");
                                $(".helper-text-mobile").addClass("d-none");
                            }
                        } else {
                            if (data.redirectUrl) {
                                window.location.href = data.redirectUrl;
                            } else {
                                customerHelpers.methods.customerFormResponse(defer, data);

                                if (data.isCustomerRegistered) {
                                    $(".already-registered").removeClass("d-none");
                                    $(".already-registered button").attr("disabled", false);
                                }

                                window.dispatchEvent(new CustomEvent("joinFailed", {
                                    detail: {
                                        fields: [{ ["dwfrm_profile_customer_email"]: data.fieldErrors[0].dwfrm_profile_customer_email }],
                                        formName: "join",
                                        type: "form error"
                                    }
                                }));
                            }
                        }
                        if (!data.isCustomerRegistered) {
                            $(".already-registered").addClass("d-none");
                            $(".already-registered button").attr("disabled", true);
                        }
                    },
                    error: function (err) {
                        if (err.responseJSON && err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        }
                        // Server error submitting form
                        defer.reject(err.responseJSON);
                    }
                });
            }, captchaAction);
        });
    },
    // Handle sign-in redirect click event, pre-fill email input, and switch to login tab
    handleSignInRedirect: function () {
        $(".sign-in-redirect").on("click", function (event) {
            event.preventDefault();
            var enteredEmail = $("#registration-form-email").val();

            $("#login-form-email").val(enteredEmail);
            $(".js-register-tab").removeClass("active");
            $(".js-login-tab").addClass("active");
            $("#register").removeClass("active");
            $("#login").addClass("active");
        });
    },

    handleSignInRedirectSummary: function () {
        $(".sign-in-redirect-summary").on("click", function (e) {
            e.preventDefault();
            var enteredEmail = $("#confirmationEmal").val();

            $("#login-form-email").val(enteredEmail);
        });
    },

    changeMobileHelpText: function () {
        $("body").on("click", ".js-account-continue-step", function (e) {
            e.preventDefault();

            if ($("#loyalty-sign-up-first").is(":checked")) {
                $(".helper-text-telephone").removeClass("d-none");
                $(".helper-text-mobile").addClass("d-none");
                $(".js-phone-label").empty().append(" " + $(".wmr-id-lable").data("phone-lable"));
            } else {
                $(".helper-text-mobile").removeClass("d-none");
            }
        });
    },

    regexPostalCode: function () {
        $("#zipCode, #accountZipCode, #postalCode, #profileZipCode, #billingZipCode, .shipping-zip-code").on("input", function () {
            var zipCodeInput = $(this).val();
            // Remove all non-numeric characters
            zipCodeInput = zipCodeInput.replace(/[^0-9]/g, "");

            if (zipCodeInput.length > 5 && zipCodeInput.length < 10) {
                // Add a hyphen after the fifth digit
                zipCodeInput = zipCodeInput.replace(/(\d{5})(\d{1,4})/, "$1-$2");
            }
            // Set the zip code input value to the modified value
            $(this).val(zipCodeInput);
        });
    },

    inputPhoneMask: function () {
        inputmask({"mask": "(999) 999-9999"}).mask("#phone, #phoneMobile, .shippingPhoneNumber, #phoneNumber, #communications-phone-sms");
    },

    openResetPasswordModal: function () {
        $("body").on("click", ".js-reset-drawer-open", function (e) {
            e.preventDefault();

            var target = $(this).data("target");
            var checkoutEmailInput = $(".registered-form").find("#email").val();

            $("#reset-password-email").val(checkoutEmailInput);
            $(".reset-password-form").removeClass("d-none");
            $(".js-email-not-exist-container").addClass("d-none");

            drawer.openDrawer(target);
            window.dispatchEvent(new CustomEvent("startPasswordReset", {}));
        });
    },

    restrictInputToNumbers: function () {
        $(".rewards-input").on("input", function () {
            this.value = this.value.replace(/[^0-9]/g, "");
        });
    },

    closeResetPasswordModal: function () {
        $("body").on("click", ".js-reset-drawer-close", function (e) {
            e.preventDefault();
            let target = $(this).closest(".drawer");

            clearResetForm();
            drawer.closeDrawer(target);
        });
    },

    /**
     * Event handler for opening the login drawer
     * @param {Event} e - The click event
     * @returns {void}
     */
    /**
     * Event handler for opening the login drawer
     * @param {Event} e - The click event
     * @returns {void}
     */
    openLoginModal: function () {
        $("body").on("click", ".js-login-drawer-open", function (e) {
            e.preventDefault();

            let target = $(".js-login-drawer");
            let option = $(this).data("option");
            let eventLocation = $(this).data("location");

            if ($(this).parents(".checkout-page").length > 0) {
                return;
            }

            if ($(".js-is-authenticated").val() === "true") {
                redirectUrl = $(this).data("action");
            }

            appendWishlistProductIdToLoginUrls(this);

            // if the user is registered then open the data-action url instead of the login drawer
            if ($("#js-a-page-customer").data("status") === "registered") {
                window.location.href = $(this).data("action");
                return;
            }

            if (!option || option === "login") {
                $(".js-login-tab").addClass("active");
                $(".js-register-tab").removeClass("active");
            } else {
                $(".js-login-tab").removeClass("active");
                $(".js-register-tab").addClass("active");
            }

            drawer.openDrawer(target);

            let customerForm = $(".js-account-continue-step").parents("form");
            let submitAction = customerForm.data("check-action");

            $(".js-account-join-first").removeClass("d-none");
            $(".js-account-join-second").addClass("d-none");
            customerForm.attr("action", submitAction);

            window.dispatchEvent(new CustomEvent("loginDrawerOpen", {
                detail: {
                    loginLocation: eventLocation ? eventLocation : "inline",
                    type: option
                }
            }));

            // TODO: separate join steps forms and remove this workaround
            $("#guest-customer").on("keyup", function (ev) {
                var keyCode = ev.keyCode || ev.which;
                if (keyCode == 13) {
                    if ($("#registration-form-email").is(":focus")) {
                        $(".js-account-continue-step").click();
                    }
                }
            });
        });
    },

    /**
     * Detects SMS check event and triggers debounceRequiredChange function
     */
    detectSmsCheck: function () {
        $("body").on("click", ".js-register-sms-optin", debounceRequiredChange);
    },

    closeLoginModal: function () {
        $("body").on("click", ".js-login-drawer-close", function (e) {
            e.preventDefault();
            let target = $(this).closest(".drawer");
            $(".helper-text-mobile, .helper-text-telephone").addClass("d-none");
            delete window.sweepstakeOnLogin;

            clearLoginForm();
            drawer.closeDrawer(target);
        });
    },

    initClearLoginErrors: customerHelpers.methods.initClearLoginErrors
};
