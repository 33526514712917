"use strict";

var utils = require("../components/utils");
var isCartPage = $(".cart-header").length > 0;

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf("?") !== -1 ? "&" : "?") + Object.keys(params).map(function (key) {
        return key + "=" + encodeURIComponent(params[key]);
    }).join("&");

    return newUrl;
}

/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */
function updateStoresResults(data, $dialog, isGeolocate) {
    var $resultsDiv = $dialog.find(".results");

    var $noresultsItem = $dialog.find(".store-locator-no-results");
    var $noLocationItem = $dialog.find(".store-locator-no-location");

    $noresultsItem.html(data.storeMessage).toggleClass("d-none", !data.hasNoStores);
    $noLocationItem.html(data.storeMessage).toggleClass("d-none", !data.locationUnavailable);

    $resultsDiv.attr("data-search-results", data.aStore.searchResults);

    if (data.locationUnavailable) {
        return;
    }

    $resultsDiv.empty()
        .data("has-results", !data.hasNoStores)
        .data("radius", data.radius)
        .data("search-key", data.searchKey);

    if (data.storesResultsHtml) {
        $resultsDiv.append(data.storesResultsHtml);
        $resultsDiv.find(".hide-store-select").removeClass("hide-store-select");
    }

    window.dispatchEvent(new CustomEvent("storeSearch", {detail: {isGeolocate: isGeolocate}}));

    $resultsDiv.find(".js-a-store-change").on("click", function (e) {
        window.dispatchEvent(new CustomEvent("storeChanged", {
            detail: {
                target: e.target
            }
        }));
    });
}

/**
 * Search for stores with new zip code
 * @param {HTMLElement} element - the target html element
 * @returns {boolean} false to prevent default event
 */
function search(element) {
    var $dialog = element.parents(".js-location-modal");
    $dialog.spinner().start();

    var $form = element.closest(".store-locator");
    var radius = $(".results").data("radius");
    var url = $form.attr("action");
    var urlParams = { radius: radius };

    var payload = $form.is("form") ? $form.serialize() : { postalCode: $form.find("[name=postalCode]").val() };
    url = appendToUrl(url, urlParams);

    $.ajax({
        url: url,
        type: $form.attr("method"),
        data: payload,
        dataType: "json",
        success: function (data) {
            $.spinner().stop();
            updateStoresResults(data, $dialog, false);
        }
    });
    return false;
}

/**
 * Opens the location modal and performs necessary UI updates
 * @param {Event} e - the event object
 */
function openLocationModal(e) {
    let windowWidth = $(window).outerWidth();
    let isMobile = windowWidth < 992;
    var $headerParent = $(e.relatedTarget).parents(".header");
    var $navParent = $(e.relatedTarget).parents(".main-menu");
    var isLocationLink = $(e.currentTarget).parents(".location");

    $("header.header-nav").removeClass("header-above");
    $(".modal-backdrop").addClass("js-location-backdrop");
    $("header.header-nav").append($(".modal-backdrop.js-location-backdrop"));
    $(".js-location-anchor").attr("aria-expanded", "true");
    $(".js-location-modal").attr("aria-hidden", "false");

    if (isMobile) {
        $("html").addClass("prevent-scroll");
        $(".page").addClass("prevent-scroll");
    }

    if ($headerParent.length > 0) {
        $headerParent.find(".navbar-location").addClass("active");
        $(".modal-backdrop").addClass("transparent");
        $("body").removeClass("modal-open").removeAttr("style");
    } else if ($navParent.length > 0) {
        $(e.currentTarget).addClass("location-modal-centered");
    } else if (isLocationLink.length > 0) {
        $(".modal-background").show();
        $(".js-location-modal").show();
        $(".js-location-modal").addClass("location-modal-centered");
    } else {
        $(e.currentTarget).addClass("location-modal-centered").attr("data-dynamic-update", true);
        utils.handleBodyScrolling(true);
    }
}

/**
 * Closes the location modal and performs necessary actions
 * @param {Event} e - the event object
 */
function closeLocationModal(e) {
    var $headerParent = $(e.target).parents("header");
    var $navParent = $(e.target).parents(".main-menu");
    var isLocationLink = $(e.currentTarget).parents(".location");
    //if the store modal was closed without store selected, select another shipping method
    if (!$(".js-selected-store-id").val()) {
        $("[value=shipToHome]").trigger("click");
    }

    $(".modal-backdrop.js-location-backdrop").remove();
    $("header").addClass("header-above");
    $(".js-location-anchor").attr("aria-expanded", "false");
    $(".js-location-modal").attr("aria-hidden", "true");

    if ($headerParent.length > 0 && isLocationLink.length === 0) {
        $headerParent.find(".navbar-location").removeClass("active");
        $(e.currentTarget).removeClass("location-modal-centered").attr("data-dynamic-update", false);
    } else if ($navParent.length > 0) {
        $(e.currentTarget).removeClass("location-modal-centered");
    } else if (isLocationLink.length > 0) {
        $(".modal-background").hide();
        $(".js-location-modal").hide();
        $(".js-location-modal").removeClass("location-modal-centered").attr("data-dynamic-update", false);
        $("html").removeClass("prevent-scroll");
        $(".page").removeClass("prevent-scroll");
    } else {
        $(e.currentTarget).removeClass("location-modal-centered").attr("data-dynamic-update", false);
        utils.handleBodyScrolling(false);
    }

    $("body").removeClass("no-scroll");
}

module.exports = {
    selectStore: function () {
        $(document).on("click", ".js-select-store", function (e) {
            var $target = $(e.target);
            var url = $target.data("url");
            var isModalFromPDP = $target.closest(".pdp-store-modal").length > 0;

            $.ajax({
                url: url,
                type: "post",
                dataType: "json",
                success: function (data) {
                    var newURL = new URL(window.location.href);
                    var $modal = $target.parents(".js-location-modal");
                    var $header = $("header");

                    if (newURL.searchParams.has("store")) {
                        newURL.searchParams.set("store", data.storeId);
                    }

                    // Update header store ID
                    $header.find(".js-selected-store-id").val(data.storeId);

                    var selectRadio = $modal.find(".modal-content").data("selectStoreForUUID");
                    if (isCartPage && selectRadio) {
                        $("#" + selectRadio).trigger("change");
                        return;
                    }

                    // Select store shipping option if action is on cart page
                    if (isCartPage) {
                        $(".js-selected-store").text(data.storeName);
                        window.location.reload();
                    }

                    // Refresh data via AJAX response if user is on classic PDP page
                    if ($modal.attr("data-dynamic-update") == "true" && !$(".js-pdp-alcohol").length) {
                        // Updates header store information
                        $header.find(".js-selected-store").text(data.storeName);
                        $header.find(".js-selected-store-id").attr("data-name", data.storeName);
                        $header.find(".js-store-item").removeClass("store-item-selected");
                        $header.find(".js-store-item[data-id='" + data.storeId + "']").addClass("store-item-selected");

                        // Updates URL
                        window.history.pushState("", "", newURL);

                        // Aplies store filter if user is on Search/Category page
                        if (!isModalFromPDP) {
                            $(document).trigger("storeFilterSelected", isModalFromPDP);
                        }

                        // Applies store query is user is on PDP
                        var $productIDItems = $(document).find(".product-id");
                        var storeID = data.storeId;
                        var storeName = data.storeName;
                        var $selectedStore = $(".store-item-selected", ".js-location-modal").length > 0 ? $(".store-item-selected", ".js-location-modal") : $target.parents(".store-item");

                        $productIDItems.each(function () {
                            var $pdpContainer = $(".product-detail[data-pid=" + $(this).text() + "]");

                            if ($pdpContainer.length) {
                                var storeData = {
                                    storeID: storeID,
                                    storeDetailsHtml: $selectedStore.find(".store-card-top .list").html(),
                                    storeName: storeName,
                                    productID: $pdpContainer.attr("data-pid"),
                                    initialLoad: false
                                };

                                $("body").trigger("store:selected", storeData);
                            }
                        });

                        if (data.storeListHTML) {
                            $("#js-a-store-locator-search").empty().append(data.storeListHTML);
                        }

                        // Hides modal
                        $(".location-modal").modal("hide");
                        $("body").removeClass("no-scroll");

                    } else {
                        window.location = newURL;
                    }
                },
                error: function () {
                    $target.spinner().stop();
                }
            });
        });
    },
    handleStoreExpand: function () {
        $(document).on("click", ".js-store-details-btn", function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();

            var item = $(this).parent();
            var storeDetails = $(".js-store-details");
            item.next().toggleClass("d-none");
            $(this).toggleClass("expanded");

            if (!$(this).hasClass("expanded")) {
                storeDetails.attr("aria-expanded", "false");
            } else {
                storeDetails.attr("aria-expanded", "true");
            }
        });
    },
    /**
     * Initializes event listeners for location modal functionality
     */
    handleStoreModal: function () {
        $(document).on("shown.bs.modal", ".js-location-modal", function (e) {
            openLocationModal(e);
        });

        $(document).on("hide.bs.modal", ".js-location-modal", function (e) {
            closeLocationModal(e);
        });

        $(document).on("click", ".js-location-backdrop", function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();

            $(".js-location-modal").modal("hide");
        });

        $(document).on("click", ".js-location-link", function (e) {
            if ($(window).outerWidth() > 992) {
                e.preventDefault();
                e.stopImmediatePropagation();
                openLocationModal(e);
            } else {
                $(".js-location-anchor")[0].click();
            }
        });

        $(document).on("click", ".js-close-location", function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            closeLocationModal(e);
        });
    },
    // Copy HTML from desktop, to privent duplicated remote includes
    mirrorHeaderMobile: function () {
        var $mobileContainer = $(document).find(".js-mobile-store-selector");

        if ($mobileContainer.length) {
            var $headerStoreRefinement = $(document).find(".navbar-location");

            var refinementHTML = $headerStoreRefinement.html();

            $mobileContainer.html(refinementHTML);
            $mobileContainer.find(".icon-map-pin").removeClass("icon-color-t300 icon-map-pin icon-md").addClass("icon-sm icon-color-p500");
            $mobileContainer.find(".location-text").css("align-items", "center").css("justify-content", "flex-start");
            $mobileContainer.find(".location-text").children(".find-location-link").addClass("margin-8-left").removeClass("medium");
            $mobileContainer.find(".location-text").children(".location-text-supratitle").removeClass("text-xxs").addClass("margin-8-left text-sm");
        }
    },
    searchStores: function () {
        $(document).on("click", ".btn-storelocator-search[type=submit]", function (e) {
            e.preventDefault();
            search($(this));
        });

        $(document).on("submit", "form.store-locator", function (e) {
            e.preventDefault();
            search($(this));
        });
    },
};
